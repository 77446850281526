import React from 'react'
import { Component } from 'react'
import { HeaderItem, StyledTitle, StyledContent, StyledWrapper } from './style'
import { Collapse } from 'react-collapse'

const ArrowUp = require('../../../../assets/images/pages/up.png')
const ArrowDown = require('../../../../assets/images/pages/down.png')

interface ItemProps {
  index: number
  title: string
  content: string
  active: boolean
  onClickTitle: (index: number) => void
}

class ItemList extends Component<ItemProps> {
  render() {
    const { index, title, content, active, onClickTitle }: ItemProps = this.props
    return (
      <StyledWrapper>
        <HeaderItem
          onClick={() => {
            onClickTitle(index)
          }}
        >
          {active ? (
            <StyledTitle active={active}>
              <b>{title}</b>
              <img src={ArrowUp} />
            </StyledTitle>
          ) : (
            <StyledTitle>
              <span>{title}</span>
              <img src={ArrowDown} />
            </StyledTitle>
          )}
        </HeaderItem>
        <Collapse isOpened={active}>
          <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
        </Collapse>
      </StyledWrapper>
    )
  }
}

export default ItemList
