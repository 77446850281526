import React from 'react'
import Layout from '../components/Layout'

import {
  Container,
  StyledRow,
  Title,
  Text,
  CollapseListContainer
} from '../components/StylesPages/faqStyle'
import CollapseList from '../components/CollapseList'

export default () => (
  <Layout themeType="dark" currentPage={'faq'}>
    <Container className="container">
      <StyledRow className="row">
        <div className="col-md-12">
          <Title>Preguntas Frecuentes</Title>
          <Text>Selecciona el tema de tu consulta para que podamos ayudarte</Text>
        </div>
      </StyledRow>
      <div className="row">
        <div className="col-md-12">
          <CollapseListContainer>
            <CollapseList />
          </CollapseListContainer>
        </div>
      </div>
    </Container>
  </Layout>
)
