import React from 'react'
import ItemList from './components/ItemList'
import { CollapseListWrapper } from './style'
class CollapseList extends React.Component {
  state = { activeIndex: null }

  faqs = [
    {
      question: '¿Qué es Grande Table?',
      response:
        'Grande Table es el Club Gourmet más grande del país, que ofrece beneficios exclusivos en reconocidos restaurantes de diferentes ciudades. Al adquirir una membresía, podrás disfrutar descuentos especiales de hasta el 25% en toda la cuenta, todos los días. Además, tendrás acceso a eventos con chefs nacionales e internacionales, delivery gratuito, preferencia en reservas, servicio de Chef en casa y mucho más.',
    },
    {
      question: '¿Cómo me puedo unir al club Grande Table?',
      response: `<p>Suscribirte a Grande Table es muy sencillo. Dirígete al menú <a href='https://www.grandetable.com.ec/membership' target="_blank">MEMBRESÍAS</a>, selecciona el tipo de pago de tu preferencia (anual o mensual), completa los datos solicitados y realiza tu pago a través de PlacetoPay. ¡Listo! En los siguientes 5 días laborables, recibirás tu tarjeta Grande Table en la dirección ingresada.  </p>`,
    },
    {
      question: '¿Cuáles son los beneficios de ser parte del club Grande Table?',
      response: `<p>Ser socio Grande Table tiene muchas ventajas, entre las que podemos nombrar descuentos del 10% al 25% en toda la cuenta, todos los días, en los restaurantes aliados.</p>
        <p>También tendrás acceso especial a eventos gastronómicos con chefs nacionales e internacionales del más alto nivel.</p>
        <p>Disfruta de un trato preferencial en los restaurantes más reconocidos del país, reservaciones y menús exclusivos para socios Grande Table, catas de vino, servicio de Chef en casa y delivery gratuito. </p>
        `,
    },
    {
      question: '¿Cuáles son los beneficios de cada restaurante?',
      response: `<p>Para conocer los beneficios de los restaurantes aliados de Grande Table, ingresa al menú <a href='https://www.grandetable.com.ec/restaurants?order=asc' target="_blank" >RESERVAS</a>, elige el establecimiento de tu preferencia y descubre a detalle los beneficios que te ofrece. </p>`,
    },
    {
      question: '¿Cómo puedo enterarme de los eventos Grande Table?',
      response: `<p>Nuestros socios Grande Table reciben notificaciones de forma recurrente, vía correo electrónico o a través de nuestro WhatsApp (099 336 0413), con información exclusiva sobre nuestros eventos, promociones y novedades.</p>
        <p>También los puedes revisar ingresando al menú <a href='https://www.grandetable.com.ec/events' target="_blank" >EVENTOS</a></p>
        `,
    },
    {
      question: '¿Recibo algún documento que certifique que soy parte del club?',
      response: 'Por supuesto, una vez que has adquirido tu membresía, recibirás tu tarjeta transaccional Grande Table.',
    },
    {
      question: '¿En cuánto tiempo recibo mi tarjeta Grande Table después de solicitarla?',
      response: `<p>A partir de la fecha del registro, tu tarjeta Grande Table tardará 12 días laborables en ser entregada. En caso de que requieras conocer el estado de tu tarjeta, puedes comunicarte a nuestro Call Center al 02 500 1100.</p>`,
    },
    {
      question: '¿Cuál es el proceso que debo seguir para hacer efectivos los beneficios de mi tarjeta  Grande Table?',
      response:
        'Tan pronto como recibas tu tarjeta Grande Table, podrás acercarte a cualquiera de nuestros establecimientos aliados y presentarla para empezar a disfrutar de los beneficios exclusivos de nuestros socios. Además, podrás realizar reservas en el restaurante de tu preferencia a través de nuestro sitio web o escribiendo a nuestro WhatsApp (099 336 0413), para una atención más personalizada.',
    },
    {
      question: '¿La tarjeta Grande Table es personal?',
      response: 'Sí, la tarjeta del Club es personal e intransferible.',
    },
    {
      question: '¿La renovación de la membresía y de la tarjeta es automática?',
      response:
        'Sí. La renovación de la membresía se realiza de forma automática cada año o de manera mensual, dependiendo de tu elección al momento de registrarte. La tarjeta se renueva cada dos años y se envía a la dirección ingresada durante el registro.',
    },
    {
      question: 'Si ya no deseo ser parte del club ¿cómo cancelo la membresía?',
      response: `<p>Si deseas cancelar tu membresía, puedes comunicarte con nosotros a los teléfonos: 02 500 1100 o 099 336 0413 y te ayudaremos con el proceso de cancelación.</p>`,
    },
    {
      question: '¿Cuál es el proceso de compra?',
      response: `<p>Para realizar una compra en Grande Table Delivery, con descuentos especiales para los miembros del Club Gourmet, sigue estos pasos: </p>
                <ol>
                  <li>Selecciona el establecimiento de tu preferencia</li>
                  <li>Elige el menú y agrégalo al carrito de compras</li>
                  <li>Dirígete al carrito de compras, verifica los datos y da clic en Siguiente (si eres socio McNamara, coloca el código promocional enviado a tu correo electrónico)</li>
                  <li>Digita tu documento de identificación para validar tu membresía Grande Table (en caso de no ser socio, el sistema no permitirá continuar el proceso)</li>
                  <li>Ingresa los datos de envío y facturación. A continuación, haz clic en Siguiente</li>
                  <li>Finaliza la compra en el Botón de Pagos (PlacetoPay)</li>
                  <li>Verifica si recibiste el correo de confirmación. ¡Pronto podrás disfrutar de tu pedido! </li>
                </ol>
      `,
    },
    {
      question: '¿Cuál es el canal de atención al cliente? ',
      response: '<p>Para cualquier consulta, puedes comunicarte a nuestro Call Center: 02 500 1100 </p>',
    },
    {
      question: '¿En qué sectores realizan las entregas? ',
      response: `<p>El tiempo de entrega depende de cada establecimiento. Por favor, revisa la información en la página del restaurante de tu preferencia ingresando al menú <a href='https://delivery.grandetable.com.ec/' target="_blank" >DELIVERY</a>, para conocer los tiempos estimados de entrega. </p>`,
    },
    {
      question: '¿Cuál es el descuento que recibiré por ser socio Grande Table? ',
      response: `El descuento depende de cada establecimiento. Por favor, revisa la información en la página del restaurante de tu preferencia ingresando al menú <a href='https://delivery.grandetable.com.ec/' target="_blank" >DELIVERY</a>, para conocer el descuento que ofrece.`,
    },
    {
      question: '¿Qué pasa si mi pedido no llega en el rango de tiempo establecido por el restaurante?',
      response: 'Para ayudarte a solventar cualquier inconveniente con tu pedido, por favor, comunícate a nuestro Call Center: 02 500 1100',
    },
    {
      question: '¿Puedo realizar pedidos de varios establecimientos en un solo proceso de compra?',
      response:
        'No podrás realizar pedidos en establecimientos diferentes en una sola compra. Si después de realizar una primera orden deseas hacer un pedido en otro establecimiento, deberás completar nuevamente el proceso de compra.',
    },
    {
      question: '¿Cuál es el horario de atención para realizar pedidos?',
      response: `Los horarios de atención y entregas varían según cada establecimiento. Por favor, revisa la información en la página del restaurante de tu preferencia ingresando al menú <a href='https://delivery.grandetable.com.ec/' target="_blank" >DELIVERY</a>, para conocer sus horarios. `,
    },
    {
      question: '¿Qué procesos de higiene siguen para el despacho y la entrega de productos? ',
      response: `Los productos que compres en Grande Table serán entregados en tu domicilio manejando estrictas normas de higiene y tomando en consideración procesos de entrega que permitan una adecuada conservación de los alimentos.`,
    },
    {
      question: '¿Cuál es el costo de envío? ',
      response: `Por tiempo limitado, el envío no tendrá costo. `,
    },
    {
      question: '¿Cuáles son las condiciones de pago? ',
      response: `<p>Tus pagos podrán ser realizados únicamente de forma corriente, debido a que por el momento no se encuentra habilitada la opción de diferir pagos. Si utilizas tu tarjeta Grande Table recibirás un descuento especial.</p>
        <p>Para más información sobre las condiciones de pago, puedes revisar nuestros <a href="https://medias.grandetable.com.ec/docs/tyc_grande_table.pdf" target="_blank" >Términos y condiciones</a></p>`,
    },
    {
      question: '¿Cómo voy a recibir mi factura? ',
      response: `La factura de tus consumos llegará al correo registrado durante el proceso de compra y será enviada directamente por el establecimiento en el que se realizó el pedido. `,
    },
    {
      question: '¿Qué debo hacer si no recibí el comprobante de pago? ',
      response: `Por cada transacción aprobada en PlacetoPay recibirás un comprobante de pago, que será enviado a la dirección de correo electrónico registrada al momento de la compra. En caso de no recibir el comprobante, podrás escribir a soporte@placetopay.com para solicitar el reenvío del documento a tu correo electrónico.`,
    },
    {
      question: '¿Qué pasa si aún no me llega el correo con mi comprobante? ',
      response: `Si continúas sin recibir el comprobante, por favor, comunícate a nuestro Call Center al 02 500 1100, para ayudarte con el inconveniente. `,
    },
    {
      question: '¿Qué pasa si realicé un pedido, pero deseo cancelarlo o devolverlo? ',
      response: `Los productos no están sujetos a devoluciones, cambios ni cancelaciones. Por favor, revisa nuestros <a href="https://medias.grandetable.com.ec/docs/tyc_grande_table.pdf" target="_blank" >Términos y condiciones</a> para mayor información. `,
    },
    {
      question: 'Qué es PlacetoPay? ',
      response: 'Es la plataforma de pagos electrónicos que usa Grande Table para procesar las transacciones generadas en su tienda virtual.',
    },
    {
      question: 'Cómo puedo pagar? ',
      response:
        'Para acceder a los beneficios exclusivos para socios Grande Table, podrás realizar tus pagos o transacciones en nuestra tienda virtual o en los restaurantes aliados, utilizando tu tarjeta Diners/Grande Table o McNamara. ',
    },
    {
      question: '¿Es seguro ingresar mis datos bancarios en este sitio web? ',
      response: `<p>Para proteger tus datos Grande Table delega en PlacetoPay la captura de la información sensible. La plataforma de pagos cumple con los estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además, tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, que garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio; de esta manera te podrás sentir seguro a la hora de ingresar la información de tu tarjeta.</p> 
                  <p>Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de dirección cambia a color verde. Estas características son visibles de inmediato, dan garantía y confianza para completar la transacción en PlacetoPay. PlacetoPay también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara. PlacetoPay es una marca de la empresa colombiana EGM Ingeniería Sin Fronteras S.A.S.</p>`,
    },
    {
      question: 'Puedo realizar el pago cualquier día y a cualquier hora?',
      response: 'Sí, en Grande Table podrás realizar tus compras en línea los 7 días de la semana, las 24 horas del día. ',
    },
    {
      question: 'Puedo cambiar la forma de pago? ',
      response:
        'Si aún no has completado tu pago en PlacetoPay, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar el método de pago. Es importante tomar en cuenta que podrás realizar el pago únicamente con tu tarjeta Grande Table, Diners Club o McNamara. ',
    },
    {
      question: 'Pagar electrónicamente tiene algún valor para mí como comprador? ',
      response: 'No, los pagos electrónicos realizados a través de PlacetoPay no generan costos adicionales para el comprador. ',
    },
    {
      question: 'Qué debo hacer si mi transacción no concluyó? ',
      response: `<p>Como primer paso, revisa si recibiste un mensaje de confirmación de tu pago al correo ingresado en el formulario de datos. Si no lo has recibido, comunícate a nuestro Call Center al 02 500 1100, para confirmar el estado de la transacción. </p>
                <p>En caso de que tu transacción haya sido rechazada, verifica si la información de la cuenta es válida, está habilitada para compras no presenciales y/o si tienes cupo o saldo disponible. Si después de esto persiste el problema, puedes remitir tu solicitud a <a href='mailto:servicioposventa@placetopay.ec'>servicioposventa@placetopay.ec</a> o comunicarte con Grande Table Delivery si se trata de un pedido a restaurante. </p>`,
    },
    {
      question: 'Qué debo hacer si no recibí el comprobante de pago? ',
      response: `<p>Por cada transacción aprobada a través de PlacetoPay, recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico ingresada al momento de pagar. Si no lo recibes, comunícate a nuestro Call Center al 02 5001 100 para solicitar el reenvío del comprobante a tu correo electrónico. Si el problema persiste, puedes remitir tu solicitud a <a href='mailto:info@grandetable.com.ec'>info@grandetable.com.ec</a> </p>`,
    },
    {
      question: 'No me llegó el producto que compré ¿qué hago? ',
      response: `<p>En caso de no haber recibido el producto que compraste en Grande Table, por favor, comunícate de forma inmediata a nuestro Call Center: 02 500 1100, o envíanos un mensaje a nuestro WhatsApp: 099 336 0413. Revisaremos tu caso para solucionar el inconveniente en el menor tiempo posible.</p>`,
    },
  ]

  setActive = (index: number) => {
    if (this.state.activeIndex === index) {
      this.setState({ activeIndex: null })
    } else {
      this.setState({ activeIndex: index })
    }
  }

  render() {
    const list = this.faqs.map((faq, index) => (
      <ItemList key={index} index={index} title={faq.question} content={faq.response} active={this.state.activeIndex === index} onClickTitle={this.setActive} />
    ))
    return <CollapseListWrapper>{list}</CollapseListWrapper>
  }
}

export default CollapseList
