import styled from 'styled-components'
import theme from '../../../../styles/theme'

interface TitleProps {
  active?: boolean
}

export const HeaderItem = styled.div`
  cursor: pointer;
  color: black;
`
export const StyledTitle = styled.h4<TitleProps>`
  margin: 14px 0;
  font-weight: ${props => (props.active === true ? 'bold' : '400')};
  letter-spacing: 2px;
  color: ${theme.purpleBrown};
  padding: 0 40px 0 25px;
  display: inline-block;
  position: relative;
  width: 100%;
  img {
    position: absolute;
    right: 13px;
    top: 5px;
  }
`
export const StyledContent = styled.div`
  margin: 0;
  padding: 0 25px 14px;
  color: ${theme.purpleBrown};
  letter-spacing: 2px;
  font-weight: 300;

  & p:last-child {
    margin-bottom: 0;
  }
`

export const StyledWrapper = styled.li`
  margin: 0;
  border-top: solid 1px rgba(151, 151, 151, 0.5);

  &:first-child {
    border-top: none;
  }
`
