import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 0;
  ${media.md`  
    padding-top: 1rem;
  `};
`

export const StyledRow = styled.div``

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 3.7px;
  color: ${theme.purpleBrown};
  margin-top: 26px;
`

export const Text = styled.p`
  color: ${theme.purpleBrown};
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 40px;
`

export const CollapseListContainer = styled.div`
  background: ${theme.lightGray};
  margin-bottom: 40px;
`
